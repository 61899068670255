body {
  background: linear-gradient(180deg, #00395c 0%, #81cfff 100%);
}
@media only screen and (max-width: 600px) {
  .DIV2 {
    margin-top: 100px;
  }
}
.styling {
  font-weight: 700;
  font-size: 70px;
  color: white;
  text-align: center;
}
.style{
  color: #FFFFFF; 
  font-size: 20px; 
  font-weight: 500;
  text-align:center;
}
@media only screen and (max-width: 600px){
  .styling {
    font-size: 20px;
  }
  .style{
    font-size: 14px;
  }
  

}
.styling2{
  font-weight: 700;
  font-size: 40px;
  color:#FFFFFF;
  margin-top:100px;
  color:#FFFFFF;
}

.style2{
  text-align: center;
  color: #DDDDDD;
  font-weight: 400;
  font-size: 22px;
  margin:50px 70px;
  width:56%;
}
.DIV4 {
 
  max-width: 100%;
  max-height: 100%;
  background: url(Images/img10.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment:bottom;

}
@media only screen and (max-width: 600px){
  .styling2 {
    /* margin-bottom: -150px; */
    font-size: 20px;
    margin-top:-50px;

  }
  .style2{
   
    font-size: 12px;
    width:100%;
  }
  
}

.styling4{
  font-size :60px;
  font-weight: 700;
}
.style4{
  font-weight: 500;
  font-size: 20px;

}
@media only screen and (max-width: 600px){
  .styling4 {
    /* margin-bottom: -150px; */
    font-size: 30px;
    text-align: center;
    

  }
  .style4{
    
    font-size: 16px;
    text-align: justify;
    margin:0px 10px;
  }
  .BTN{
    margin:0px 130px;
  }

  /* .style2{
   
    font-size: 12px;
    width:100%;
  }
   */
}
.heading{
  font-size :150px;
  font-weight: 700;
}
.TEXT2{
  font-weight: 500;
  font-size: 25px;

}
@media only screen and (max-width: 600px){
  .heading {
    /* margin-bottom: -150px; */
    font-size:80px;
    text-align: center;
    

  }
  .TEXT2{
    
    font-size: 16px;
    text-align: center;
  }
 
}
 
/* .IMG{
   margin-top: 100px; 
}
.TEXT1{
 padding-top: 70px;
 width:500px;
} */
@media only screen and (max-width: 600px){
  .IMG {
    order:1;
    width:250px;
  }
  .TEXT1{
    order:0;
  }
}
.Support{
  padding-left:150px;
}
@media only screen and (max-width: 600px){
  .Support{
    padding:12px;
    margin-bottom: 100px;
  }
}


.nav-item {
  font-size: 14px;
}
.nav-link.page {
  color: #003555;
}
.Image3 {
  position: relative;
  left: 140px;
  top: 70px;
  max-width: 100vw;
  height: auto;
  /* z-index: 2; */
}

.Image1 {
  position: absolute;
  z-index: 2;
  max-width: 100vw;
  height: auto;
}
.Image2 {
  position: relative;
  right: 140px;
  top: 70px;
  max-width: 100vw;
  height: auto;
  /* z-index: 2; */
}

.DIV2 {
  position: relative;

  top: -250px;
  z-index: 3;
}
.Accord {
  background: transparent;
}


@media only screen and (max-width: 600px){
  .DIV4 {
    background-position: 0px 230px;
  }
  
}

.DIV5 {
  position: relative;
  top: -100px;
  max-width: auto;
  max-height: auto;
  background: url(Images/img11.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.DIV6 {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.5));
  background: #ffffff;
  border-radius: 20px;
  width: 450px;
}
@media only screen and (max-width: 600px){
  .DIV6{
    width:350px;
    height:460px;
    padding:0px;

  }
}

input {
  max-width: 700px;
  height: 50px;
}
label {
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
}
